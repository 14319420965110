import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

const MentionsLegales = () => {
  return (
    <Layout>
      <Seo
        title="Mentions légales"
        description="Découvrez les mentions légales de notre site."
      />
      <section className="Legal container">
        <h1>Mentions légales</h1>
        <h2>Éditeur responsable</h2>
        <ul>
          <li>Nom de la société : Legal Plan</li>
          <li>Forme juridique : SPRL</li>
          <li>
            Adresse postale du siège social :<br />
            Avenue de la Fontaine 4<br />
            BE-1435 Mont-Saint-Guibert
          </li>
          <li>Téléphone : +32 10 65 07 76</li>
          <li>Mail : info@legalplan.be</li>
          <li>Numéro BCE : 0718.759.706</li>
          <li>Numéro TVA : BE0718759706</li>
        </ul>
        <h2>Politique de confidentialité</h2>
        <p>
          Legal Plan est le responsable de traitement pour les données qui sont
          collectées sur ce site Web.
          <br />
          Vous pouvez nous contacter par mail via info@legalplan.be
        </p>
        <h2>Cookies</h2>
        <h3>Cookies de mesure d’audience</h3>
        <p>
          En vue d’adapter le site aux demandes de ses visiteurs, nous mesurons
          le nombre de visites, le nombre de pages vues, l'activité des
          visiteurs sur le site ainsi que leur fréquence de retour. Google
          Analytics est l'outil de statistiques utilisé par Legal Plan.
        </p>
        <h2>Propriété intellectuelle</h2>
        <p>
          Les textes, images et éventuelles vidéos de ce site Web sont la
          propriété intellectuelle de Legal Plan. Ces contenus ne peuvent être
          réutilisés, copiés, complétement ou partiellement sans autorisation.
        </p>
      </section>
    </Layout>
  );
};

export default MentionsLegales;
